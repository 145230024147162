<template>
  <eagle-list
    @updatePopupSelectData="updatePopupSelectData"
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'provider-product-selector',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
  },
  methods: {
    updatePopupSelectData(data) {
      this.$emit('updateData', data)
    },
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.productApi.index(this.providerId, params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.productApi.patch(this.providerId, target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(this.providerId, target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.productApi.batch(this.providerId, targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.productApi.batchDelete(this.providerId, targets)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
